<template>
  <div class="Item">
    <div class="name">
      {{ item.id }}
    </div>
    <div class="divider" />
    <div class="word">
      {{ item.word }}
    </div>
    <div
      class="image"
      :style="{ backgroundImage: `url(${item.image})` }" />
  </div>
</template>

<script>
export default {
    inject: ['config'],
    props: {
        itemId: {
            type: String,
            required: true
        }
    },
    computed: {
        item() {
            return this.config.items[this.itemId];
        }
    }
};
</script>

<style scoped lang="scss">
.Item {
    background-color: rgba(black, 0.05);
    border-radius: 0.5em;
    padding: 1em;
    padding-bottom: 2em;
    color: white;
}
.name {
    font-size: 200%;
    text-align: center;
    text-transform: capitalize;
}
.divider {
    height: 1px;
    background-color: rgba(black, 0.05);
    margin: 1em 0;
}
.word {
    text-align: center;
    color: rgba(white, 0.7);
    font-size: 120%;
    &::first-letter {
        color: rgba(white, 0.9);
        font-weight: bold;
        text-transform: uppercase;
    }
}
.image {
    $size: 10em;
    width: $size;
    height: $size;
    margin: 0 auto;
    margin-top: 1em;
    border-radius: 0.5em;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
</style>
