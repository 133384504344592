import keyBy from 'lodash-es/keyBy';
import chunk from 'lodash-es/chunk';
import last from 'lodash-es/last';
import Item from './components/Item';
import PracticeConfiguration from './components/PracticeConfiguration';
import MemorizeItem from './components/MemorizeItem';
import backgroundImage from './images/background.png';

const images = require.context('./images/items', true, /\.(png|jpg)$/);

function getImageUrl(name) {
    try {
        return images(`./${name}.jpg`);
    } catch (err) {
        return null;
    }
}

const itemsRaw = {
    en: [
        ['a-1', 'ape'], // old: alligator
        ['b-2', 'bear'],
        ['c-3', 'chihuaua'],
        ['d-4', 'dolphin'],
        ['e-5', 'elephant'], // old: eskimo dog (huskey)
        ['f-6', 'flamingo'], // old: fish
        ['g-7', 'giraffe'],
        ['h-8', 'horse'],
        ['i-9', 'iguana'],
        ['j-10', 'jaguar'],
        ['k-11', 'kangaroo'], // old: koala bear
        ['l-12', 'lama'],
        ['m-13', 'mouse'],
        ['n-14', 'nemo'], //  old: neanderthal
        ['o-15', 'otter'],
        ['p-16', 'panda'], // old: panther
        ['q-17', 'quetzal'], // old: quail
        ['r-18', 'reindeer'],
        ['s-19', 'sea horse'],
        ['t-20', 'tiger'],
        ['u-21', 'uakari monkey'],
        ['v-22', 'vampire bat'], // old: vampyr
        ['w-23', 'walrus'], // old: wallaby
        ['x-24', 'spider (looks like x)'], // old: xtra big hamster
        ['y-25', 'yak'],
        ['z-26', 'zebra']
    ],
    no: [
        ['a-1', 'ape'], // old: alligator
        ['b-2', 'bjørn'],
        ['c-3', 'chihuaua'],
        ['d-4', 'delfin'],
        ['e-5', 'elefant'], //  old: eskimohund (husky)
        ['f-6', 'flamingo'], // old: fisk
        ['g-7', 'giraff'],
        ['h-8', 'hest'],
        ['i-9', 'iguana'],
        ['j-10', 'jaguar'],
        ['k-11', 'kenguru'], // old: koala-bjørn
        ['l-12', 'lama'],
        ['m-13', 'mus'],
        ['n-14', 'nemo'], // old: neandertaler
        ['o-15', 'oter'],
        ['p-16', 'panda'], // old: panter
        ['q-17', 'quetzal'], // old: quail
        ['r-18', 'reinsdyr'],
        ['s-19', 'sjøhest'],
        ['t-20', 'tiger'],
        ['u-21', 'ugle', 'u-21_no'], // old: uakari ape
        ['v-22', 'vampyr-flaggermus'], // old: vampyr
        ['w-23', 'hvalross (høres ut som W)'], // old: wallaby
        ['x-24', 'edderkopp (ligner på X)'], // old: xtra stor hamster
        ['y-25', 'yak-okse'],
        ['z-26', 'zebra'],
        ['æ-27', 'ærfugl'],
        ['ø-28', 'øyenstikker'],
        ['å-29', 'ål'],
        ['.-30', 'punktum = pinnsvin', 'punktum-30']
    ]
};

export default function generateConfig({ language }) {
    const items = itemsRaw[language].map(([id, word, filename]) => {
        const label = id.split('-')[0];
        return {
            id,
            word,
            label,
            image: getImageUrl(`${filename || id}`)
        };
    });
    const itemsMap = keyBy(items, item => item.id);
    return {
        items: itemsMap,
        itemIds: items.map(item => item.id),
        itemGroups: chunk(items, 10).map((sublist, index) => {
            return {
                index,
                label: `${sublist[0].label}-${last(sublist).label}`,
                itemIds: sublist.map(x => x.id)
            };
        }),
        BrowseItem: Item,
        MemorizeItem,
        PracticeConfiguration,
        backgroundImage,
        systemId: 'alphabet',

        getImagesInGroup(group) {
            return group.itemIds.map(itemId => itemsMap[itemId].image);
        }
    };
}
