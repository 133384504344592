<template>
  <div class="PracticeConfiguration">
    <div
      class="loremIpsum"
      v-html="$t('text')" />
    <br>
    <GameSelectionSection
      :game-ids="gameIds"
      :selected-game-id="selectedGame"
      @game-selected="gameId => (selectedGame = gameId)" />
  </div>
</template>

<translations>
  text: 'Øv deg på alfabet-systemet og vis at du behersker kodene i spillene.<br><br>OBS: Ved å bruke kloneteknikken kan du bruke de samme kodene og spille flere ganger.'
</translations>

<script>
import gameHelper from '@/modules/games-shared/game-helper';
import GameSelectionSection from '@/modules/systems-shared/components/GameSelectionSection';

export default {
    components: { GameSelectionSection },
    inject: ['config', 'module'],
    data() {
        return {
            selectedNumbers: [],
            selectedGame: null,
            gameIds: ['shopping', 'brainstorm']
        };
    },
    computed: {
        isReady() {
            return !!this.selectedGame;
        }
    },
    watch: {
        isReady: {
            handler: function (val) {
                this.$emit('ready-status-changed', val);
            },
            immediate: true
        }
    },
    methods: {
        onSelectedNumbersChanged(numbers) {
            this.selectedNumbers = numbers;
        },
        selectGame(gameId) {
            this.selectedGame = gameId;
        },
        getGameSessionOptionsForShopping() {
            return {
                numberOfItems: this.config.itemIds.length,
                numberingStyle: 'letter-and-number',
                itemMemorizeTimeLimit: 30,
                itemQuizTimeLimit: 30
            };
        },
        getGameSessionOptionsForBrainstorm() {
            return {
                numberOfItems: this.config.itemIds.length,
                numberingStyle: 'letter-and-number',
                itemMemorizeTimeLimit: 30,
                itemQuizTimeLimit: 30
            };
        },
        getGameSessionOptions() {
            if (this.selectedGame === 'shopping') {
                return this.getGameSessionOptionsForShopping();
            } else if (this.selectedGame === 'brainstorm') {
                return this.getGameSessionOptionsForBrainstorm();
            }
        },
        startPractice() {
            // NOTE: used from parent component
            if (!this.isReady) {
                return;
            }

            gameHelper.createPracticeSession({
                gameId: this.selectedGame,
                exitUrl: this.$route.path,
                sessionOptions: this.getGameSessionOptions(),
                navigate: true
            });
        }
    }
};
</script>

<style scoped lang="scss">
.PracticeConfiguration {
    display: flex;
    flex-direction: column;
}
.loremIpsum {
    padding: 2em;
    color: rgba(white, 0.8);
    line-height: 1.5em;
}
</style>
